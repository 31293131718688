<template>
  <PageWithLayout :is-body-bg="false" :is-body-middle="true">
    <div class="container">
      <Button
        btn-size="large"
        btn-style="primary"
        :disabled="isLoading"
        @onClickBtn="onClickInvalidateCache"
      >
        캐시 무효화하기
      </Button>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';

import apiPath from '@/services/apiPath';
import { GET_REQUEST } from '@/services/http';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import CacheInvalidationViewModel from '../viewModel/CacheInvalidationViewModel';

export default {
  name: 'CacheInvalidation',
  components: {
    PageWithLayout,
    Button,
  },
  data() {
    return {
      viewModel: new CacheInvalidationViewModel(),
      isLoading: false,
    };
  },
  methods: {
    onClickInvalidateCache() {
      this.isLoading = true;

      const path = `${apiPath.INVALIDATE_CACHE}`;

      GET_REQUEST(path)
        .then(
          // eslint-disable-next-line no-unused-vars
          success => {
            store.dispatch('commonToast/fetchToastStart', '성공!');
          },
          failed => {
            store.dispatch('commonToast/fetchToastStart', failed.msg);
          },
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
}
</style>
